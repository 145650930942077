<template>
  <div class="container-padding grid-columns-3 component-footer color-00">
    <a href="/" class="flex logo"><img src="https://via.placeholder.com/288x58?text=adomicilio" alt="Adomicilio"></a>
    <div class="social justify-end flex">
      <a class="mr-2" href="/">
        <img src="../assets/twitter.svg" alt="Twitter">
      </a>
      <a class="mr-2" href="/">
        <img src="../assets/facebook.svg" alt="Facebook">
      </a>
      <a href="/">
        <img src="../assets/instagram.svg" alt="Instagram">
      </a>
    </div>
    <div class="powered justify-end flex">
      <p class="alt inline-flex align-center"><span class="inline-flex p-1">Powered by</span> <img
        src="../assets/a-domicilio-footer.png" alt="A domicilio"></p>
    </div>
    <div class="apps row">
      <a href="/" class="mr-2">
        <img src="../assets/googleplay.png" alt="Google Play">
      </a>
      <a href="/">
        <img src="../assets/appstore.png" alt="App Store">
      </a>
    </div>
    <div class="links flex">
      <p class="mr-1 ml-a"><a href="/"> Facturación</a></p>
      <p class="mr-1"><a href="/">Términos y Condiciones</a></p>
      <p class=""><a href="/">Aviso de Privacidad</a></p>
    </div>
  </div>
</template>
<script>
export default {
  name: 'componentFooter'
}
</script>
