<template>
  <nav ref="mainNav" :class="`main-nav ${isScrolled ? 'compact' : ''} ${menuActive ? 'menu-active' : ''}`">
  <button class="sm btn-menu btn-clean mr-1" @click="menuActive = !menuActive">
      <svg width="18" height="12" viewBox="0 0 18 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 12H18V10H0V12ZM0 7H18V5H0V7ZM0 0V2H18V0H0Z" fill="#12529F"/>
      </svg>
    </button>
    <router-link to="/" class="logo">
      <img src="https://via.placeholder.com/288x58?text=adomicilio" alt="Adomicilio">
    </router-link>
    <div class="menus flex justify-center">
      <label class="icon-left sm mb-1 mt-1">
        <input type="text" class="box-shadow-strong">
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M12.5 11H11.71L11.43 10.73C12.41 9.59 13 8.11 13 6.5C13 2.91 10.09 0 6.5 0C2.91 0 0 2.91 0 6.5C0 10.09 2.91 13 6.5 13C8.11 13 9.59 12.41 10.73 11.43L11 11.71V12.5L16 17.49L17.49 16L12.5 11ZM6.5 11C4.01 11 2 8.99 2 6.5C2 4.01 4.01 2 6.5 2C8.99 2 11 4.01 11 6.5C11 8.99 8.99 11 6.5 11Z" fill="black"/>
        </svg>
      </label>
      <router-link to="/menu">Menú</router-link>
      <router-link to="/promotions">Promociones</router-link>
      <router-link to="/locations">Sucursales</router-link>
      <router-link to="/contact">Contacto</router-link>
    </div>
    <div class="controls">
      <button class="btn-white-secondary no-sm btn-image-only">
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M12.5 11H11.71L11.43 10.73C12.41 9.59 13 8.11 13 6.5C13 2.91 10.09 0 6.5 0C2.91 0 0 2.91 0 6.5C0 10.09 2.91 13 6.5 13C8.11 13 9.59 12.41 10.73 11.43L11 11.71V12.5L16 17.49L17.49 16L12.5 11ZM6.5 11C4.01 11 2 8.99 2 6.5C2 4.01 4.01 2 6.5 2C8.99 2 11 4.01 11 6.5C11 8.99 8.99 11 6.5 11Z"
            fill="black"/>
        </svg>
      </button>
      <template v-if="!logged">
        <RouterLink to="/login" class="btn no-md">Iniciar Sesión</RouterLink>
        <RouterLink to="/account" class="btn md btn-image-only">
          <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.73682 8.61328C11.0849 8.61328 12.9868 6.71141 12.9868 4.36328C12.9868 2.01516 11.0849 0.113281 8.73682 0.113281C6.38869 0.113281 4.48682 2.01516 4.48682 4.36328C4.48682 6.71141 6.38869 8.61328 8.73682 8.61328ZM8.73682 10.7383C5.89994 10.7383 0.236816 12.162 0.236816 14.9883V16.0508C0.236816 16.6352 0.714941 17.1133 1.29932 17.1133H16.1743C16.7587 17.1133 17.2368 16.6352 17.2368 16.0508V14.9883C17.2368 12.162 11.5737 10.7383 8.73682 10.7383Z" fill="#F36E44"/>
          </svg>
        </RouterLink>
      </template>
      <template v-else>
        <RouterLink to="/account" class="btn no-md">
          <img class="round" src="https://via.placeholder.com/23x23?text=adomicilio"
               alt="A domicilio"/>
          Mi cuenta
        </RouterLink>
        <RouterLink to="/account" class="btn btn-image-only md">
          <img class="round" src="https://via.placeholder.com/23x23?text=adomicilio"
               alt="A domicilio"/>
        </RouterLink>
      </template>
      <button @click="this.setFavoritesDialogVisibility(!orderDialogVisible)" class="btn-image-only">
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M10 19.175L8.55 17.855C3.4 13.185 0 10.105 0 6.325C0 3.245 2.42 0.824997 5.5 0.824997C7.24 0.824997 8.91 1.635 10 2.915C11.09 1.635 12.76 0.824997 14.5 0.824997C17.58 0.824997 20 3.245 20 6.325C20 10.105 16.6 13.185 11.45 17.865L10 19.175Z"
            fill="white"/>
        </svg>
      </button>
      <button @click="this.setOrderDialogVisibility(!orderDialogVisible)" class="btn-image-only">
        <svg width="16" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M14 4H12C12 1.79 10.21 0 8 0C5.79 0 4 1.79 4 4H2C0.9 4 0 4.9 0 6V18C0 19.1 0.9 20 2 20H14C15.1 20 16 19.1 16 18V6C16 4.9 15.1 4 14 4ZM6 8C6 8.55 5.55 9 5 9C4.45 9 4 8.55 4 8V6H6V8ZM8 2C9.1 2 10 2.9 10 4H6C6 2.9 6.9 2 8 2ZM12 8C12 8.55 11.55 9 11 9C10.45 9 10 8.55 10 8V6H12V8Z"
            fill="white"/>
        </svg>
        <span>1</span>
      </button>
    </div>
  </nav>
  <order-dialog ref="orderDialogComponent" v-bind:propVisible="orderDialogVisible"/>
  <favorites-dialog ref="favoritesDialogComponent" v-bind:propVisible="favoritesDialogVisible"/>
</template>

<script>
import OrderDialog from '@/components/OrderDialog'
import FavoritesDialog from '@/components/FavoritesDialog'

export default {
  name: 'ComponentMainNav',
  components: { OrderDialog, FavoritesDialog },
  data () {
    return {
      orderDialogVisible: false,
      favoritesDialogVisible: false,
      logged: false,
      navSize: 0,
      isScrolled: false,
      menuActive: false
    }
  },
  mounted () {
    this.setHeight()
    // console.log(this.$refs.mainNav.clientHeight)
    window.addEventListener('scroll', this.handleScroll)
  },
  unmounted () {
    window.removeEventListener('scroll', this.handleScroll)
  },
  watch: {
    $route (to, from) {
      this.menuActive = false
    }
  },
  methods: {
    setHeight () {
      this.navSize = this.$refs.mainNav.clientHeight
    },
    handleScroll (event) {
      this.isScrolled = this.navSize <= window.pageYOffset
    },
    setOrderDialogVisibility (val = true) {
      this.$refs.orderDialogComponent.setOrderDialogVisibility(val)
    },
    setFavoritesDialogVisibility (val = true) {
      this.$refs.favoritesDialogComponent.setFavoritesDialogVisibility(val)
    }
  }
}
</script>
