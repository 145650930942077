<template>
  <component-main-nav></component-main-nav>
  <router-view/>
  <component-footer></component-footer>
</template>
<script>
import ComponentFooter from '@/components/Footer'
import ComponentMainNav from '@/components/Nav'
export default {
  components: {
    ComponentFooter,
    ComponentMainNav
  }
}

</script>
<style lang="scss">
  @import "./assets/styles/style";
</style>
