<template>
  <Dialog v-model:visible="visible" class="favorites-dialog">
    <div class="info">
      <h2 class="color-secondary">Tus Favoritos</h2>
      <hr class="bg-color-20">
    </div>
    <div class="content mt-2">
      <div class="item color-secondary flex align-center">
        <img class="round-4 mr-3 mb-0" src="https://via.placeholder.com/45x45?text=adomicilio" alt="Adomicilio">
        <p class="mt-0 mr-1 mb-0 label">Gringa de Bistec</p>
        <button class="ml-a btn-clean">
          <svg width="12" height="16" viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 14C1 15.1 1.9 16 3 16H9C10.1 16 11 15.1 11 14V4H1V14ZM12 1H9L8 0H4L3 1H0V3H12V1Z" fill="#12529F"/>
          </svg>
        </button>
      </div>
    </div>
  </Dialog>
</template>

<script>
import Dialog from 'primevue/dialog'

export default {
  name: 'FavoritesDialog',
  data () {
    return {
      visible: false
    }
  },
  methods: {
    setFavoritesDialogVisibility (val = true) {
      this.visible = val
    }
  },
  components: {
    Dialog
  }
}

</script>
