import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/Home.vue')
  },
  {
    path: '/style-guide',
    name: 'Styles Guide',
    component: () => import(/* webpackChunkName: "style guide" */ '../views/StyleGuide.vue')
  },
  {
    path: '/locations',
    name: 'Locations',
    component: () => import(/* webpackChunkName: "about" */ '../views/Locations.vue')
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "about" */ '../views/Login.vue')
  },
  {
    path: '/sign-in',
    name: 'SignIn',
    component: () => import(/* webpackChunkName: "about" */ '../views/SignIn.vue')
  },
  {
    path: '/menu',
    name: 'Menu',
    component: () => import('../views/Menu.vue')
  },
  {
    path: '/promotions',
    name: 'Promotions',
    component: () => import('../views/Promos.vue')
  },
  {
    path: '/contact',
    name: 'Contact',
    component: () => import(/* webpackChunkName: "Contact" */ '../views/Contact.vue')
  },
  {
    path: '/account',
    name: 'Account',
    component: () => import('../views/profile/ProfileTemplate.vue'),
    redirect: '/account/user',
    children: [
      {
        path: 'user',
        components: {
          account: () => import('../views/profile/Account.vue')
        }
      },
      {
        path: 'cards',
        components: {
          account: () => import('../views/profile/Cards.vue')
        }
      },
      {
        path: 'addresses',
        components: {
          account: () => import('../views/profile/Addresses.vue')
        }
      },
      {
        path: 'cash',
        components: {
          account: () => import('../views/profile/Cash.vue')
        }
      },
      {
        path: 'orders',
        components: {
          account: () => import('../views/profile/Orders.vue')
        }
      },
      {
        path: 'order',
        components: {
          account: () => import('../views/profile/Order.vue')
        }
      }
    ]
  },
  {
    name: 'Checkout',
    path: '/checkout',
    component: () => import('../views/Checkout.vue')
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
