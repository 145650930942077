<template>
  <Dialog v-model:visible="visible" class="order-dialog">
    <div class="info">
      <h2 class="color-secondary">Tu Pedido</h2>
      <p class="color-secondary">
        <svg class="mr-1" width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M17 0H1V2H17V0ZM18 10V8L17 3H1L0 8V10H1V16H11V10H15V16H17V10H18ZM9 14H3V10H9V14Z" fill="#12529F"/>
        </svg>
        El Califa Condesa
      </p>
    </div>
    <div class="content mt-2">
      <div class="item flex align-start justify-space-between mb-3">
        <Dropdown v-model="productQty1" :options="qtyOptions" :value="productQty1"/>
        <div class="ml-1 mr-1 desc">
          <p class="mt-0 label mr-a color-secondary">Gringa</p>
          <p class="mt-0 alt color-60">
            Sin piña, con limones
            “De favor cortar por la mitad”
          </p>
        </div>
        <p class="mt-0 color-primary">$50.00</p>
        <button class="ml-1 btn-clean">
          <svg width="12" height="16" viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 14C1 15.1 1.9 16 3 16H9C10.1 16 11 15.1 11 14V4H1V14ZM12 1H9L8 0H4L3 1H0V3H12V1Z" fill="#12529F"/>
          </svg>
        </button>
      </div>
      <div class="note">
        <div class="grid-columns-1 pt-2 pb-2 content">
          <label class="icon-top">
            <Textarea placeholder="Comentarios de la orden..." v-model="comments" rows="5" cols="100"/>
            <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M13.167 0.416992H1.83366C1.05449 0.416992 0.424076 1.05449 0.424076 1.83366L0.416992 14.5837L3.25033 11.7503H13.167C13.9462 11.7503 14.5837 11.1128 14.5837 10.3337V1.83366C14.5837 1.05449 13.9462 0.416992 13.167 0.416992ZM3.95866 5.37533H11.042C11.4316 5.37533 11.7503 5.69408 11.7503 6.08366C11.7503 6.47324 11.4316 6.79199 11.042 6.79199H3.95866C3.56908 6.79199 3.25033 6.47324 3.25033 6.08366C3.25033 5.69408 3.56908 5.37533 3.95866 5.37533ZM8.20866 8.91699H3.95866C3.56908 8.91699 3.25033 8.59824 3.25033 8.20866C3.25033 7.81908 3.56908 7.50033 3.95866 7.50033H8.20866C8.59824 7.50033 8.91699 7.81908 8.91699 8.20866C8.91699 8.59824 8.59824 8.91699 8.20866 8.91699ZM11.042 4.66699H3.95866C3.56908 4.66699 3.25033 4.34824 3.25033 3.95866C3.25033 3.56908 3.56908 3.25033 3.95866 3.25033H11.042C11.4316 3.25033 11.7503 3.56908 11.7503 3.95866C11.7503 4.34824 11.4316 4.66699 11.042 4.66699Z"
                fill="#747474"/>
            </svg>

          </label>
        </div>
      </div>
    </div>
    <div class="last flex direction-column">
      <hr class="min bg-primary">
      <router-link to="/checkout" class="btn ml-a mr-a mt-3 align-center justify-space-between" @click="setOrderDialogVisibility(false)">
        <span class="clean flex align-center">
        <svg class="mr-1" width="16" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M14 4H12C12 1.79 10.21 0 8 0C5.79 0 4 1.79 4 4H2C0.9 4 0 4.9 0 6V18C0 19.1 0.9 20 2 20H14C15.1 20 16 19.1 16 18V6C16 4.9 15.1 4 14 4ZM6 8C6 8.55 5.55 9 5 9C4.45 9 4 8.55 4 8V6H6V8ZM8 2C9.1 2 10 2.9 10 4H6C6 2.9 6.9 2 8 2ZM12 8C12 8.55 11.55 9 11 9C10.45 9 10 8.55 10 8V6H12V8Z"
            fill="white"/>
        </svg>
          Terminar y Ordenar</span><span class="clean ml-1">$100.00</span></router-link>
    </div>
  </Dialog>
</template>

<script>
import Dialog from 'primevue/dialog'
import Textarea from 'primevue/textarea'
import Dropdown from 'primevue/dropdown'

export default {
  name: 'OrderDialog',
  data () {
    return {
      visible: false,
      comments: '',
      productQty1: 1,
      qtyOptions: [1, 2, 3]
    }
  },
  methods: {
    setOrderDialogVisibility (val = true) {
      this.visible = val
    }
  },
  components: {
    Dialog,
    Textarea,
    Dropdown
  }
}

</script>
